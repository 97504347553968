<template>
    <div class="patroladd-page">
        <div class="tab-bar">
            <van-tabs v-model:active="tabActive" :animated="false" color="#DF5F0B" title-active-color="#DF5F0B">
                <van-tab replace title="新　增" to="/client/patrol/add" />
                <van-tab replace title="任务列表" to="/client/patrol/list" />
            </van-tabs>
        </div>
        <div class="patrol-body">
            <div class="patrol-object">
                <van-cell is-link @click="openPoleDialog">
                    <template #title>
                        <span class="custom-title">巡更电杆</span>
                    </template>
                    <template #value>
                        <span class="custom-right">{{
                            poleChecked.length > 0 ? `已选择${poleChecked.length}根电杆` : "请选择巡更电杆"
                        }}</span>
                    </template>
                </van-cell>
                <gk-select
                    v-model:active="unitPicker.active"
                    v-model:show="unitPicker.show"
                    v-model:value="unitPicker.value"
                    :columns="unitPicker.list"
                    label="巡更单位"
                    @confirm="dataForm.unit_id = unitPicker.active.id"
                />
            </div>
            <div class="patrol-parameter">
                <van-cell>
                    <template #title>
                        <span class="custom-title">任务名称</span>
                    </template>
                    <template #value>
                        <input
                            v-model="dataForm.name"
                            class="patrol-frequency"
                            placeholder="请输入任务名称"
                            type="text"
                        />
                    </template>
                </van-cell>
                <gk-select
                    v-model:active="dayPicker.active"
                    v-model:show="dayPicker.show"
                    v-model:value="dayPicker.value"
                    :columns="dayPicker.list"
                    label="巡更周期"
                    @confirm="dataForm.task_day = dayPicker.active.id"
                />
                <van-cell>
                    <template #title>
                        <span class="custom-title">填写巡更次数</span>
                    </template>
                    <template #value>
                        <input
                            v-model="dataForm.task_count"
                            class="patrol-frequency"
                            placeholder="请输入巡更次数"
                            type="number"
                        />
                    </template>
                </van-cell>
                <van-cell>
                    <template #title>
                        <span class="custom-title">是否持续</span>
                    </template>
                    <template #value>
                        <van-switch v-model="dataForm.is_cycle" :active-value="1" :inactive-value="0" size="20">
                            是否持续
                        </van-switch>
                    </template>
                </van-cell>
                <div class="submit-button">
                    <van-button :block="true" color="#DF5F0B" @click="onSubmit">保存任务</van-button>
                </div>
            </div>
        </div>
    </div>

    <van-action-sheet
        v-model:show="poleDialog.show"
        :close-on-click-overlay="false"
        cancel-text="确定"
        title="请选择巡更电杆"
    >
        <div class="content">
            <van-cell v-for="(item, index) in poleList">
                <!-- 使用 title 插槽来自定义标题 -->
                <template #title>
                    <van-checkbox-group v-model="poleChecked" icon-size="16px">
                        <van-checkbox :name="item.id" shape="square">
                            {{ item.equipment }}
                        </van-checkbox>
                    </van-checkbox-group>
                </template>
            </van-cell>
        </div>
    </van-action-sheet>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { ADD_PATROL, GET_PATROL_POLE_DETAIL, GET_PATROL_UNIT_LIST } from "@/api/patrol";
import { Toast } from "vant";
import usePublic from "@/hooks/usePublic";

let { $router } = usePublic();

// tab切换
let tabActive = ref(0);

onMounted(() => {
    getPoleList();
    getUnitOptions();
});

let dataForm = ref({
    name: null,
    unit_id: null,
    task_day: null,
    task_count: null,
    is_cycle: 0
});

// 电杆列表
let poleChecked = ref([]);

let poleList = ref([]);

let getPoleList = () => {
    GET_PATROL_POLE_DETAIL({
        limit: 10000
    }).then((res) => {
        poleList.value = res.data.data.list;
        console.log(res.data.data);
    });
};

let poleDialog = ref({ show: false });

let openPoleDialog = () => {
    poleDialog.value.show = true;
};

// 巡更单位
let unitPicker = ref({
    show: false,
    value: "",
    active: {},
    list: []
});

let getUnitOptions = () => {
    GET_PATROL_UNIT_LIST({}).then((res) => {
        unitPicker.value.list = res.data.data.map((item) => {
            return {
                id: item.id,
                text: item.name
            };
        });
        console.log(unitPicker.value.list);
    });
};

// 规则天数
let dayPicker = ref({
    show: false,
    value: "",
    active: {},
    list: [
        { id: 1, text: "天" },
        { id: 30, text: "月" }
    ]
});

let onSubmit = () => {
    ADD_PATROL({
        ...dataForm.value,
        poles: poleChecked.value
    }).then((res) => {
        if (res.data.code === 200) {
            Toast({
                type: "success",
                message: "新增成功",
                onClose: () => {
                    $router.push({ name: "ClientPatrolList" });
                }
            });
        }
    });
};
</script>
<style lang="scss" scoped>
.patroladd-page {
    min-height: 100vh;
    background: #f0f0f0;

    .tab-bar {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }

    .patrol-body {
        margin-top: 10px;

        .patrol-object {
            .content {
                padding: 10px 0;
                font-size: 14px;
            }
        }
    }

    .patrol-parameter {
        margin-top: 10px;

        .patrol-frequency {
            text-align: right;
            border: none;
            width: 80%;
        }

        .patrol-frequency::placeholder {
            font-size: 14px;
            color: #999;
        }

        .submit-button {
            width: 320px;
            margin: 20px auto 0 auto;
            border-radius: 10px;
        }
    }
}
</style>
